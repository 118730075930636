import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueResource from 'vue-resource'
import moment from 'moment'
import momenttimezone from 'moment-timezone'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueResource)
Vue.use(moment)
Vue.use(momenttimezone)

new Vue({
    el: '#login', // where <div id="app"> in your DOM contains the Vue template
    name: 'Login',
    data: {
        api: {
            url: '/ajax/',
            options: {emulateJSON: true},
        },
        pagination: {
            currentPage: 1,
            rows: 0,
            perPage: 12,
        },
        video: {},
        category: 'category',
        title: 'video',
        id: 0,
        value: null,
        fav: false,
        favBg: 'white',
    },

    watch: {
    },
    
    methods: {


        // Format the date
        formatDate: function(date) {
            
            var d = date;
            
            if(typeof date.date !== 'undefined') {
                d = date.date; 
            }

            return moment(d).tz('Europe/London').format('YYYY-MM-DD');
        },
        
        // Format a number
        numberFormat: function (value) { 
            return (Number(value)||0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },

        getVideo: function() {

            var data = this.$refs.data;
            this.category = data.dataset.cat;
            this.title = data.dataset.video;
            this.id = data.dataset.id;
    
            let query = {
                pagination: this.pagination,
                cat: this.category,
                video: this.video,
                id: this.id, 
            };

            this.$http.post(this.api.url + 'video', query, this.api.options).then(function(res) {

                if (res.body.status) {
                    this.video = res.body.video[0];
                    this.fav = res.body.fav;
                    this.$forceUpdate();
                } else {

                    this.$bvToast.toast(res.body.message, {
                        title: 'Myintim Notification',
                        autoHideDelay: 5000,
                        appendToast: false
                    });
                      
                    console.log(res.body.message);
                }
            }).catch(function(err) {
                this.$bvToast.toast('An error happened during page load: ' + err.body.detail, {
                    title: 'Myintim Notification',
                    autoHideDelay: 5000,
                    appendToast: false
                });
                  
                console.error(err);
            });

        },

        toggleFavorite: async function() {
    
            let query = {
                video: this.video,
                fav: this.fav,
            };

            try {

                let togglefav = await this.toggleFav(query);

                if (togglefav.body.status) {
                    this.fav = togglefav.body.fav;
                    if(this.fav > 0) {
                        this.favBg = 'black';
                    } 
                    this.$forceUpdate();
                } else {

                    this.$bvToast.toast(togglefav.body.message, {
                        title: 'Myintim Notification',
                        autoHideDelay: 5000,
                        appendToast: false
                    });
                        
                    console.log(togglefav.body.message);
                }

            } catch(err) {
                this.$bvToast.toast('An error happened during page load: ' + err.body.detail, {
                    title: 'Myintim Notification',
                    autoHideDelay: 5000,
                    appendToast: false
                });
                    
                console.error(err);
            }
        },

        toggleFav: function (query) {
            return this.$http.post(this.api.url + 'togglefav', query, this.api.options);
        }

    },
    mounted: function() {
    }
});